import React, {forwardRef} from 'react';
import style from '../styling/components/Practical.module.scss'


const Practical = forwardRef((_, ref) => {

  const DATES = [
    {
      day: 'Maandag',
      hours: '8u - 12u & 14u - 19u',
    },
    {
      day: 'Dinsdag',
      hours: '8u - 12u & 14u - 19u',
    },
    {
      day: 'Woensdag',
      hours: '8u - 12u & 14u - 19u',
    },
    {
      day: 'Donderdag',
      hours: '8u - 12u & 14u - 19u',
    },
    {
      day: 'Vrijdag',
      hours: '8u - 12u & 14u - 18u30',
    },
    {
      day: 'Zaterdag',
      text: 'Bel naar de wachtdienst <1733',
    },
    {
      day: 'Zondag',
      text: 'Bel naar de wachtdienst <1733'
    }
  ]

  const Openingshours = (date) => {
    let text, span
    if(date.hours === undefined) {
      span =`${date.text.split('<')[1]}`
      text = date.text.split('<')[0];
    }

    return(
      <div className={style.dayWrapper}>
        <p className={style.day}>{date.day}</p>
        {
          date.hours === undefined
            ? <p className={style.hours}>{text}<a href={`tel:${span}`}>{span}</a></p>
            : <p className={style.hours}>{date.hours}</p>
        }
      </div>
    )
  };

  return(
    <section className={style.practicalWrapper} ref={ref}>
      <article className={style.location}>
        <h1 className={style.title}>
          Locatie
        </h1>
        <p className={style.address}>
          Boterbloemstraat 1, 8300 Westkapelle.
        </p>
        <p className={style.underlineText}>
        Auto parkeren in de straat.
        </p>
        <div className={style.map}>
          <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d32761.54999153528!2d3.2910903862708594!3d51.328301995797254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c4a7553fdf7efd%3A0x38c51507f2734750!2sDokter%20Lagrou%20Brecht!5e0!3m2!1snl!2sbe!4v1676649621214!5m2!1snl!2sbe" 
          style={{border: 'none'}} allowFullScreen="" 
          loading="lazy" referrerPolicy="no-referrer-when-downgrade"
            title="Address"
          className={style.googleMap}>
          </iframe>
        </div>
      </article>
      <article className={style.openingshours}>
        <h1 className={style.title}>
          Openingsuren
        </h1>
        {
          DATES.map((date, i) => <Openingshours key={i} {...date}/>)
        }
      </article>
    </section>
  )
});

export default Practical;
