import React, { useEffect } from 'react';
import style from '../../styling/general.module.scss';
import { Cross1Icon } from '@radix-ui/react-icons';

const LeaveModal = ({ closeModal }) => {

  useEffect(() => {
    const lock = () => {
      document.body.style.overflowY = 'hidden';
    }
    lock();

    return () => {
      document.body.style.overflowY = 'initial';
    }
  }, [])

  return (
    <div className={style.modalOverlay} onClick={() => closeModal()}>
      <div className={style.modal}>
        <Cross1Icon className={style.crossIcon} onClick={() => closeModal()} />
        <h1 className={style.modalTitle}>Nieuw adres van de praktijk!</h1>
        <p style={{ fontSize: '22px' }}>
          Beste patiënten,<br/><br/>
          Vanaf maandag 3 februari zijn wij verhuisd naar Boterbloemstraat 1 in Westkapelle. Daarnaast is ons telefoonnummer gewijzigd. U kunt ons vanaf die datum bereiken via 050 15 38 55.
        </p>
      </div>
    </div>
  )
};

export default LeaveModal;
