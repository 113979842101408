import React from 'react';
import style from '../../styling/components/MailButton.module.scss';

const MailButton = ({mail}) => {

  return(
    <a href={`mailto:${mail}`} className={style.mailButton}>
      <i className={style.icon}/>
      {mail}
    </a>
  )
};

export default MailButton;
